<template>
  <b-card class="mt-0 pt-0">
    <!-- <b-row>
      <b-col
      cols="12"
      class="mt-1"
      >
        <h4 class="mb-2">
          Settings
        </h4>
      </b-col>
    </b-row> -->

    <!-- <form-wizard
      color="#7838ff"
      :title="null"
      :subtitle="null"
      shape="square"
      class="mb-3"
      ref="wizard"
      :start-index="0"
      @on-complete="formSubmitted"
    > -->
    <!-- accoint details tab -->
    <!-- <tab-content
        title="Profile"
        :before-change="validationForm"
      > -->
    <validation-observer
      ref="accountRules"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2 ml-0 pl-0"
        >
          <h3
            class="mb-0 title-tab color-text-dark"
            style="font-weight: 700; color: #181E38; font-size: 28px;"
          >
            {{ totalCols ? 'Complete your profile' : 'Personal information'}}
          </h3>
          <!-- <small class="text-muted">*required</small> -->
        </b-col>
      </b-row>

      <b-row>
        <b-col :md="totalCols ? totalCols : '6'">
          <b-row>
            <b-col
              md="12"
              class="mb-1"
            >
              <!-- media -->
              <b-media no-body>
                <b-media-aside class="m-auto">
                  <b-link>
                    <b-avatar
                      ref="previewEl"
                      v-model="usuarioData.avatar"
                      :src="usuarioData.avatar"
                      :text="avatarText(usuarioData.nombre ? (usuarioData.nombre.includes(' ') ? usuarioData.nombre : usuarioData.nombre + ' ' + usuarioData.nombre.charAt(1)) :  usuarioData.email ? usuarioData.email + ' ' + usuarioData.email.charAt(1): 'AN')"
                      size="70px"
                      badge
                      badge-variant="info"
                      badge-offset="-1px"
                      button
                      @click="$refs.refInputEl.$el.click()"
                      :style="usuarioData.avatar ? 'background: #fff !important;' : 'background: #242150;'"
                    >
                      <template #badge>
                        <feather-icon
                          icon="Edit2Icon"
                          style="color: #fff; padding: 0px;"
                          size="13"
                        />
                      </template>
                    </b-avatar>
                    <feather-icon
                      icon="XIcon"
                      style="color: #fff; padding: 0px; position: relative; top: -25px; background: rgb(255, 84, 84); height: 20px; width: 20px; border-radius: 10px; left: -20px;"
                      size="13"
                      v-if="usuarioData.avatar"
                      @click.prevent="deleteAvatar"
                    />
                  </b-link>
                  <!--/ avatar -->
                  <b-form-file
                    ref="refInputEl"
                    v-model="profileFile"
                    accept=".jpg, .png, .gif, .webp"
                    :hidden="true"
                    plain
                  />
                </b-media-aside>

                <!-- <b-media-body class="mt-75 ml-75">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        Upload
                      </b-button>

                    </b-media-body> -->
              </b-media>
              <!--/ media -->
            </b-col>

            <b-row>
              <b-col sm="12">
                <b-form-group
                  label-for="first-name"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Full name</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Full Name"
                    rules="required|max: 22"
                  >
                    <b-form-input
                      v-model="usuarioData.nombre"
                      placeholder="First Name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label="Email"
                >
                  <b-form-input
                    :value="usuarioData.email"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-for="contact"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Phone number</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.contact"
                    name="contact"
                    placeholder="Phone"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label-for="address1"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Address 1</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.address1"
                    placeholder="Write your address"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label-for="address2"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Address 2</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.address2"
                    placeholder="Write your address"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-for="company"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Company</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.company"
                    placeholder="Company"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label-for="country"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Country</span>
                  </template>
                  <v-select
                    v-model="usuarioData.country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    class="countryProfile"
                    :options="optionsCountry"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label-for="stateOrRegion"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >State/Region</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.stateOrRegion"
                    placeholder="State/Region"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label-for="city"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >City</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.city"
                    placeholder="City"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label-for="zipCode"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Zip code</span>
                  </template>
                  <b-form-input
                    v-model="usuarioData.zipCode"
                    placeholder="Zip code"
                  />
                </b-form-group>
              </b-col>
              <!-- </b-row> -->
            </b-row>
            <div class="w-100 mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-continue-addsite float-right"
                style="border-radius: 50px; height: 46px; width: 148px;"
                @click.prevent="formSubmitted"
              >
                Save changes
              </b-button>
              <b-link
                class=""
                style="font-size: 15px; text-decoration: underline; color: #7838ff;"
                @click.prevent="showOpenCompleteProfile"
                v-if="totalCols"
              >
                <small>Skip</small>
              </b-link>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- </tab-content> -->
    <!-- <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">Before</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>

          <wizard-button v-else @click.native="formSubmitted" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" :disabled="cargaAction"><div class="loader" v-if="loadingWizard"></div>  {{props.isLastStep ? 'Update profile' : 'Next'}}</wizard-button>
        </div>
      </template> -->
    <!-- </form-wizard> -->
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar,
  BFormTextarea, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, max,
} from '@validations'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    vSelect,

    // Form Wizard
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    totalCols: {
      type: String,
    }
  },
  data() {
    return {
      required,
      max,
      profileFile: null,
      password: '',
      passwordConfirm: '',
      email: '',
      usuarioData: {},
      customerId: '',
      fileImg: '',
      optionsSelected: [
        { text: 'Orange', value: 'orange' },
      ],
      loadingWizard: false,
      optionsCountry: [
        { title: 'United States' },
        { title: 'Canada' },
        { title: 'Mexico' },
        { title: 'Guatemala' },
        { title: 'Belize' },
        { title: 'Honduras' },
        { title: 'El Salvador' },
        { title: 'Nicaragua' },
        { title: 'Costa Rica' },
        { title: 'Panama' },
      ],
    }
  },
  computed: {
    ...mapState(['cargaAction', 'usuario']),
  },
  watch: {
    profileFile(val) {
      // console.log(val)
      this.fileImg = val
      // this.usuarioData.id = this.usuario.uid
      this.usuarioData.fileImg = this.fileImg
      this.editarGeneralUsuario(this.usuarioData)
      if (val) {
        const reader = new FileReader()
        reader.readAsDataURL(val)
        reader.onload = e => {
          // console.log(e.target.result)
          this.usuarioData.avatar = e.target.result
        }
      } else {
        this.usuarioData.avatar = ''
      }
    },
  },
  methods: {
    ...mapActions(['editarGeneralUsuario', 'cerrarSesion']),
    deleteAvatar() {
      db.collection('Users').doc(this.usuarioData.id).update({
        foto: ''
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
              title: `Avatar updated.`,
              icon: 'CoffeeIcon',
              variant: 'success',
          },
        })

        db.collection('Users').doc(this.usuarioData.id).get()
        .then(user => {
          var dataToUpdate = user.data()
          dataToUpdate.foto = dataToUpdate.foto ? dataToUpdate.foto : user.data().foto ? user.data().foto : '',
          // update owner projects
          db.collection('projects').where('owned.uid', '==', this.usuarioData.id).get()
          .then(projectQuery => {
            if(projectQuery.size >= 1) {
              projectQuery.forEach(project => {
                db.collection('projects').doc(project.id).update({
                  owned: dataToUpdate
                })
              })
            }
          })

          // update owner site
          db.collection('sites').where('owned.uid', '==', this.usuarioData.id).get()
          .then(siteQuery => {
            if(siteQuery.size >= 1) {
              siteQuery.forEach(site => {
                db.collection('sites').doc(site.id).update({
                  owned: dataToUpdate
                })
              })
            }
          })

          // update data users in projects
          if(user.data().teams && user.data().teams.length >= 1) {
            user.data().teams.forEach(team => {
              db.collection('projects').doc(team).collection('members').where('uid', '==', this.usuarioData.id).get()
              .then(invitationsProject => {
                if(invitationsProject.size >= 1) {
                  invitationsProject.forEach(invitation => {
                    if(invitation.data().uid) {
                      db.collection('projects').doc(team).collection('members').doc(invitation.id).update({
                        imgU: dataToUpdate.foto ? dataToUpdate.foto : user.data().foto ? user.data().foto : '',
                        name: dataToUpdate.nombre
                      })
                    }
                  })
                }
              })
            })
          }
        })
      })
      .catch(e => console.log(e))
    },
    setUsuario() {
      if(this.usuarioData.nombre) {
        if(this.customerId) {
          let configUpdCustomerName = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/update-name-customer',
            headers: { 
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              customerId: this.customerId,
              userName: this.usuarioData.nombre ? this.usuarioData.nombre : this.usuarioData.email
            })
          };

          this.axios(configUpdCustomerName)
          .then(() => {
            console.log('update name customer');
          })
          .catch(e => {
            console.log(e.message);
          })
        }
      }

      this.editarGeneralUsuario(this.usuarioData)
      // this.loadingWizard = false
      this.submitForm = false
    },
    showOpenCompleteProfile() {
      localStorage.setItem('openComplete', false)
    },
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            // this.loadingWizard = true
            this.submitForm = true
            this.setUsuario()
            this.showOpenCompleteProfile()
          } else {
            // this.loadingWizard = false
            this.submitForm = false
          }
        })
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAdicional() {
      return new Promise((resolve, reject) => {
        this.$refs.adicionalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  created() {
    const user = auth.currentUser

    const ref = db.collection('Users').doc(user.uid)
    ref.onSnapshot(doc => {
      this.customerId = doc.data().customerId ? doc.data().customerId : ''
      this.usuarioData = {}
      this.usuarioData = {
        id: doc.data().uid,
        nombre: doc.data().nombre,
        email: doc.data().email,
        contact: doc.data().contact ? doc.data().contact : '',
        address1: doc.data().address1 ? doc.data().address1 : '',
        address2: doc.data().address2 ? doc.data().address2 : '',
        company: doc.data().company ? doc.data().company : '',
        country: doc.data().country ? doc.data().country : 'United States',
        stateOrRegion: doc.data().stateOrRegion ? doc.data().stateOrRegion : '',
        city: doc.data().city ? doc.data().city : '',
        zipCode: doc.data().zipCode ? doc.data().zipCode : '',
        avatar: doc.data().foto ? doc.data().foto : '',
        // firstName: doc.data().firstName ? doc.data().firstName : doc.data().firstName,
        // lastName: doc.data().lastName ? doc.data().lastName : '',
        // email: doc.data().email,
        // role: doc.data().role,
        // avatar: doc.data().foto,
        // contact: doc.data().contact
      }
      this.email = doc.data().email
    })

    // var obj = {
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // };

    // var data = JSON.stringify({
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // });
    // this.$http.get(`https://us-central1-nycpg-69e96.cloudfunctions.net/app/api/auth/update/usuarios/data/${obj.id}/${obj.password}`)
    // .then(res => {
    //     console.log(res.data, res.status)
    // })
    // https://us-central1-nycpg-69e96.cloudfunctions.net/updateAuthUsers

    // var config = {
    //   method: 'post',
    //   url: 'https://us-central1-nycpg-69e96.cloudfunctions.net/app/api/auth/update/usuarios/data',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    //   data : data
    // };

    // this.axios(config)
    // .then(res => {
    //     console.log(res.data, res.status)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
    // .catch(e => {
    //     console.log(e)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

<style>
.countryProfile .vs__clear {
  display: flex;
  padding-top: 3px;
}
.badge-info {
  font-size: 15px !important;
}
/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  /* margin: 60px auto; */
  font-size: 2px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.vs__dropdown-toggle {
  background: #fff !important;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.title-tab {
  font-size: 24px;
  width: 700px;
}
</style>
