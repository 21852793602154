<template>
    <div style="padding-bottom: 80px;">
        <!-- <members-project :idsite="idsite" :show-open-members-project.sync="showOpenMembersProject" /> -->
        <!-- <accept-invitation :idsite="idsite" :show-open-accept-invitation.sync="showOpenAcceptInvitation" /> -->
        <complete-profile :show-open-complete-profile.sync="openComplete" />
        <activate-site
            :idsite="idsite"
            :show-open-archive-site.sync="showOpenArchiveSite"
        />

        <b-row class="align-items-center mb-4">
            <b-col md="6" sm="8" class="col1-all-projects">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    :to="{ name: 'new-project' }"
                    class="btn-all-proj-newp"
                    style="height: 44px;"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span
                        class=""
                        style=""
                    >New project</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    class="ml-1 btn-all-proj-news"
                    style="height: 44px;"
                    :to="{ name: 'new-site' }"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span
                        class=""
                        style=""
                    >New Site</span>
                </b-button>
            </b-col>

            <b-col md="6" sm="4" class="col2-all-projects">
                <div class="d-flex float-right">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        style="height: 44px; background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important;"
                        :to="{ name: 'archive-sites' }"
                        class="p-auto mr-50 btn-all-proj-arch"
                        v-if="existArchivedSites"
                    >
                        <feather-icon
                            icon="ArchiveIcon"
                            class="mr-50"
                        />
                        <span class="" style="position: relative; top: -1px;">Archived sites</span>
                    </b-button>
                </div>
            </b-col>
        </b-row>

        <!-- no projects and sites -->
        <b-overlay
            :show="loaderProjects"
            rounded="xl"
            no-fade
            v-if="(rowsAllProjects.length === 0 && rowsAcceptInvitation.length === 0) || datasuggest.data[0].sites.length === 0"
        >
            <b-row>
                <b-col
                    md="12"
                    class="p-0"
                >
                    <div class="w-100 d-lg-flex align-items-center justify-content-center mt-4">
                        <b-img-lazy
                            fluid
                            :src="noSitesImg"
                            alt="no sites img"
                            loading="lazy"
                        />
                    </div>
                    <div class="w-100 d-lg-flex align-items-center justify-content-center mt-1">
                        <span
                            class=""
                            style="font-size: 20px;"
                        >No sites yet?</span>
                    </div>
                    <div class="w-100 d-lg-flex align-items-center justify-content-center mt-1">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="btn-nosites-addnew  pt-0"
                            :to="{ name: 'new-site', params: {origin: 'my-sites'} }"
                            style="font-size: 24px; border-radius: 10px; height: 48px; padding-top: 5px !important;"
                            variant="secondary"
                        >
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                                size="20"
                            />
                            <span class="align-middle">Add a New Site</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-overlay>

        <b-link style="font-weight: 700; font-size: 16px !important; color: #181E38 !important; color: #181E38 !important; max-width: 100%;" class="title-all-projects" v-if="(rowsAllProjects.length >= 1 || rowsAcceptInvitation.length >= 1) && datasuggest.data[0].sites.length >= 1">My projects</b-link>
        <b-overlay
            :show="loaderProjects"
            rounded="xl"
            no-fade
            v-if="(rowsAllProjects.length >= 1 || rowsAcceptInvitation.length >= 1) && datasuggest.data[0].sites.length >= 1"
        >
            <div class="top-scroll-horizontal-projects" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2" style="height: 20px;"></div></div>
            <b-table-simple
                hover
                small
                caption-top
                responsive
                striped
                id="tab-err-sc1"
                class="table-projects vgt-table"
            >
                <b-thead>
                    <b-tr v-if="rowsAllProjects.length >= 1">
                        <b-td class="thTableProjects">Project name</b-td>
                        <b-td class="thTableProjectsSites">Sites</b-td>
                        <b-td class="thTableProjectsOwned">Owner</b-td>
                        <!-- <b-td class="thTableProjectsMembers">Team members</b-td> -->
                    </b-tr>
                </b-thead>
                <b-tbody v-if="rowsAllProjects.length >= 1">
                    <b-tr v-for="(row, index) in rowsAllProjects" :key="index">
                        <b-td v-if="row.label" class="tdTableProjects" @click.prevent="logIdProject(row)">
                            <span class="" :style="`font-weight: 400; font-size: 15px !important; color: #181E38 !important; display: flex; ${!row.id ? 'margin-top: 0px;' : 'margin-top: 0px;'}`">
                                <div :style="`width: 12px !important; min-width: 12px !important; height: 12px; border-radius: 4px; background: ${row.color}; margin-top: 6px;`" class="mr-50"></div>
                                <b-link v-if="row.label === 'My sites'" style="color: #181E38 !important; max-width: 100%;">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                                <b-link v-if="row.label !== 'My sites' && row.idsite" style="color: #181E38 !important; max-width: 100%;">{{ row.label.substring(0, 100) + (row.label.length > 100 ? '...' : '') }}</b-link>
                            </span>
                        </b-td>
                        <b-td class="tdTableProjectsSites" @click.prevent="logIdProject(row)">
                            <span class="" style="font-size: 15px;">
                                {{ row.counterSites ? row.counterSites : 0 }} sites
                            </span>
                        </b-td>
                        <b-td v-if="row.own" class="tdTableProjectsOwned" @click.prevent="logIdProject(row)">
                            <div v-if="row.own === 'me'">
                                <div class="d-flex mb-50" v-if="(row.permission === 'owner' || row.permission === 'admin' || row.permission === 'edit' || row.permission === 'view')">
                                    <b-avatar
                                        size="27"
                                        :src="row['ownedBy'].foto ? row['ownedBy'].foto : ''"
                                        variant="light-dark"
                                        class="badge-minimal"
                                        alt="img user"
                                        :text="avatarText(!row['ownedBy'].foto && row['ownedBy'].nombre ? row['ownedBy'].nombre : !row['ownedBy'].foto && row['ownedBy'].email ? row['ownedBy'].email + ' ' + row['ownedBy'].email.charAt(1) : '')"
                                        style="background: #ddd !important;"
                                    >
                                        <span v-if="!row['ownedBy'].foto && !row['ownedBy'].nombre && !row['ownedBy'].email">{{ avatarText(row['ownedBy'].nombre) }}</span>
                                    </b-avatar>

                                    <div class="ml-25 mt-50">
                                        <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;">
                                            Me
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="d-flex">
                                    <b-avatar
                                        size="27"
                                        :src="row['ownedBy'].foto ? row['ownedBy'].foto : ''"
                                        variant="light-dark"
                                        class="badge-minimal"
                                        alt="img user"
                                        style="background: #ddd !important;"
                                        :text="avatarText(!row['ownedBy'].foto && row['ownedBy'].nombre ? row['ownedBy'].nombre : !row['ownedBy'].foto && row['ownedBy'].email ? row['ownedBy'].email + ' ' + row['ownedBy'].email.charAt(1) : '')"
                                    >
                                        <span v-if="!row['ownedBy'].foto && !row['ownedBy'].nombre && !row['ownedBy'].email">{{ avatarText(row['ownedBy'].nombre) }}</span>
                                    </b-avatar>

                                    <div class="ml-25 mt-50">
                                        <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;" v-if="userData.uid === row['ownedBy'].uid">
                                            Me
                                        </p>
                                        <p class="mb-0 " style="font-size: 13px; line-height: 14px; color: #656B85;" v-else>
                                            {{ row['ownedBy'].nombre }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                </b-tbody>
                <b-tbody v-else-if="rowsAllProjects.length === 0">
                    <b-tr>
                        <b-td colspan="9">
                            <span>No projects</span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-overlay>
    </div>
</template>

<script>
import {
    BOverlay, BTableSimple, BThead, BTbody, BTr, BTd, BLink, BAvatar, BImgLazy, BButton, BAvatarGroup, BBadge, BSpinner, VBTooltip,
    BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'
import MembersProject from '@/views/extensions/acl/table/sidebar/MembersProject.vue'
import AcceptInvitation from '@/views/extensions/acl/table/sidebar/AcceptInvitation.vue'
import CompleteProfile from '@/views/extensions/acl/table/sidebar/CompleteProfile.vue'
import ActivateSite from '@/views/extensions/acl/table/sidebar/ActivateSite.vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { db, auth, firebase, storage } from '@/firebase'

export default {
    data() {
        return {
            loaderProjects: true,
            rowsProjects: [],
            rowsAllProjects: [],
            rowsAcceptInvitation: [],
            userData: '',
            counterTotalSites: 0,
            extraWP: 0,
            idsite: '',
            showOpenMembersProject: false,
            showOpenAcceptInvitation: false,
            openComplete: false,
            events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
            loadErrors: true,
            noSitesImg: require('@/assets/images/banner/new-proyect.svg'),
            firstLogin: JSON.parse(localStorage.getItem('firstLogin')),
            chargeOneTime: false,
            showOpenArchiveSite: false,
            loaderSearch: true,
            existArchivedSites: false,

            // autosuggest
            datasuggest: {
                data: [{
                    sites: [],
                }],
            },
            searchSite: '',
            suggestionsSites: [],
            selected: null,
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search",
            },
            sectionConfigs: {
                sites: {
                    limit: 60,
                    onSelected: selected => {
                        this.selected = selected.item
                    },
                },
                default: {
                    limit: 60,
                    onSelected: selected => {
                        this.selected = selected.item
                    }
                },
            },
            limit: 60,
        }
    },
    components: {
        BOverlay,
        BTableSimple,
        BThead,
        BTbody,
        BTr,
        BTd,
        BLink,
        BAvatar,
        BImgLazy,
        BButton,
        BAvatarGroup,
        BBadge,
        BSpinner,
        BRow,
        BCol,
        BCard,
        BCardText,
        MembersProject,
        AcceptInvitation,
        CompleteProfile,
        ActivateSite,
        VueAutosuggest,
    },
    setup() {
        return {
            avatarText,
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    watch: {
        selected(val) {
            // console.log(val)
            this.getSite(val)
        },
        // rowsAcceptInvitation(val) {
        //     if(val && val.length >= 1) {
        //         var existInvitation = false
        //         val.forEach(row => {
        //             if(row.id && row.label && row.own === 'other' && row.invitation.statusInv === 'sended') {
        //                 setTimeout(() => {
        //                     this.showOpenAcceptInvitation = true
        //                 }, 2000)
        //                 existInvitation = true
        //             }
        //         })

        //         if(!existInvitation) {
        //             this.showOpenAcceptInvitation = false
        //         }
        //     }
        // }
    },
    methods: {
        logIdMembersProject(val) {
        //     this.idsite = val
        //     this.showOpenMembersProject = true
        },
        logIdAcceptInvitation(val) {
        //     this.idsite = val
        //     this.showOpenAcceptInvitation = true
        },
        logIdCompleteProfile() {
            this.openComplete = true
            localStorage.setItem('openComplete', true)
        },
        logIdProject(val) {
            if(val.project === 'My sites') {
                this.$router.push({ name: 'sites-no-project', params: {id: val.idsite} })
            } else {
                this.$router.push({ name: 'project-sites', params: {id: val.idsite, name: val.label, own: val.own} })
            }
        },
        logIdArchive(val) {
            // console.log(val)
            this.idsite = val
            this.showOpenArchiveSite = true
        },
        fetchResults(text) {
            const { searchSite } = this
            if (searchSite) {
                this.suggestionsSites = []
            }

            const filteredDevelopers = this.datasuggest.data[0].sites.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.domain.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            if(filteredDevelopers.length) {
                filteredDevelopers.length && this.suggestionsSites.push({ data: filteredDevelopers })
            } else {
                this.suggestionsSites.push({ data: [{color: '', domain: '', domainFixed: '', id: '', idsite: '', img: '', imgSite: '', invitation: '', label: '', name: '', own: '', project: '', projectid: '', status: ''}] })
            }
        },
        getSite(val){
            if(val.id) {
                if(val.id && val.own === 'other' && val.invitation.statusInv === 'sended') {
                    this.logIdAcceptInvitation(val)
                } else if(val.status === 'archive') {
                    this.logIdArchive(val)
                } else {
                    this.$router.push({ name: 'view-errors', params: {id: val.id, imgSite: val.img, name: val.name, url: val.domain, project: val.projectid, own: val.own} })
                }
            }
        },
        renderSuggestion(suggestion) {
            const site = suggestion.item
            if(this.loaderSearch && this.datasuggest.data[0].sites.length === 0) {
                return (
                    <div class="py-50">
                        <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                            <b-spinner class="align-middle"></b-spinner>
                        </span>
                    </div>
                )
            } else {
                if(suggestion.item.id) {
                    return (
                        <div class="py-50">
                            {site.project !== 'My sites' ?
                            <span class="mr-50" style="line-height: 0.8rem !important; font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 12px; display: flex; margin-top: 0px;">
                            <div style="width: 12px; height: 12px; border-radius: 4px; margin-top: 6px;" class="mr-50"></div>
                            <span style="color: #A7AAB9 !important; line-height: 0.8rem !important;">Project</span>
                            </span>
                            : ''}
                            <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                            <div style={"width: 12px; height: 12px; border-radius: 4px; background: "+site.color+"; margin-top: 6px;"} class="mr-50"></div>
                            <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{site.project.length > 35 ? site.project.substring(0, 35) + '...' : site.project}</span>
                            </span>
                            <div class="d-flex align-items-center my-50">
                            <b-avatar square src={site.img} class="mr-50"></b-avatar>
                            <div class="detail" style="line-height: 0.8rem !important;">
                                <b-card-text class="mb-0" style="font-size: 16px; line-height: 1.2rem !important;">{site.name.length > 55 ? site.name.substring(0, 55) + '...' : site.name}</b-card-text>
                                <b-card-text class="text-muted mb-0" style="font-size: 14px; line-height: 1rem !important;">{site.domainFixed.length > 35 ? site.domainFixed.substring(0, 35) + '...' : site.domainFixed}</b-card-text>
                            </div>
                            </div>
                            { site.status === 'archive' ? 
                            <b-button
                                variant="secondary"
                                style="background: #F0F1F7 0% 0% no-repeat padding-box !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 2px 7px; border-radius: initial;"
                                class="p-auto mr-50"
                            >
                                <feather-icon
                                icon="ArchiveIcon"
                                class="mr-50"
                                />
                                <span class="align-middle ">Archived</span>
                            </b-button>
                            : '' }
                        </div>
                    )
                } else {
                    if(this.loaderSearch) {
                        return (
                            <div class="py-50">
                                <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                                    <b-spinner class="align-middle"></b-spinner>
                                </span>
                            </div>
                        )
                    } else {
                        return (
                            <div class="py-50">
                                <span style="font-weight: 700; font-size: 12px !important; color: #181E38 !important; display: flex; margin-top: 0px; line-height: 0.8rem !important;">
                                <span style="color: #181E38 !important; line-height: 0.8rem !important; padding-top: 6px;">{'No results'}</span>
                                </span>
                            </div>
                        )
                    }
                }
            }
        },
        getStatusCompletedProfile() {
            setTimeout(() => {
                this.openComplete = localStorage.getItem('openComplete') === 'false' || !localStorage.getItem('openComplete') ? false : true
            }, 500)
        },
        getWithScroll() {
            const primerElemento = document.getElementById('tab-err-sc1');
            const segundoElemento = document.getElementById('tab-err-sc2');

            if(primerElemento) {
                const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
                segundoElemento.style.width = ancho + 'px';
            }
        },
    },
    created() {
        localStorage.removeItem('licenseProcess')
        localStorage.removeItem('configRunLicense')
        this.existArchivedSites = false
        // this.SiteID = ''
        this.loaderProjects = true
        const user = auth.currentUser
        if(user) {
            db.collection('Users').doc(user.uid).get()
            .then(userLogDoc => {
                db.collection('Users').doc(user.uid).collection('projects').get()
                .then(userDoc => {
                    // console.log(userDoc.size)
                    this.datasuggest.data[0].sites = []
                    userDoc.forEach(eleUserProject => {
                        var ownerData = eleUserProject.data().owner
                        // { foto: "", email: "vendor4@shops.gt", name: "", uid: "NnRNP89vtmaCPI4eV2OhqaBCAwm2", status: "active" }
                        ownerData.name = userLogDoc.data().nombre ? userLogDoc.data().nombre : ''
                        ownerData.foto = userLogDoc.data().foto ? userLogDoc.data().foto : ''
                        ownerData.email = userLogDoc.data().email ? userLogDoc.data().email : ''
                        ownerData.uid = userLogDoc.id
                        ownerData.status = userLogDoc.data().status ? userLogDoc.data().status : ''
                        if(eleUserProject.data().name === 'My sites') {
                            this.rowsAllProjects.unshift({
                                mode: 'span',
                                html: false,
                                label: eleUserProject.data().name,
                                color: eleUserProject.data().color,
                                idsite: eleUserProject.data().id,
                                id: eleUserProject.data().id,
                                title: eleUserProject.data().name,
                                count: eleUserProject.data().numberSites,
                                permission: 'owner',
                                totalInv: eleUserProject.data().numberTeamMembers,
                                members: eleUserProject.data().teamMembers,
                                own: 'me',
                                ownedBy: ownerData,
                                counterErr: 0,
                                loadErrors: false,
                                counterSites: eleUserProject.data().numberSites,
                                invitation: 'own',
                            })
                        } else if(eleUserProject.data().owner.uid === user.uid) {
                            this.rowsAllProjects.push({
                                mode: 'span',
                                html: false,
                                label: eleUserProject.data().name,
                                color: eleUserProject.data().color,
                                idsite: eleUserProject.data().id,
                                id: eleUserProject.data().id,
                                title: eleUserProject.data().name,
                                count: eleUserProject.data().numberSites,
                                permission: 'owner',
                                totalInv: eleUserProject.data().numberTeamMembers,
                                members: eleUserProject.data().teamMembers,
                                own: 'me',
                                ownedBy: ownerData,
                                counterErr: 0,
                                loadErrors: false,
                                counterSites: eleUserProject.data().numberSites,
                                invitation: 'own',
                            })
                        } else {
                            var filterUserMember = eleUserProject.data().teamMembers.filter(ele => ele.id === user.uid)

                            if(filterUserMember.length >= 1) {
                                var memberPerm = filterUserMember[0].role
                                this.rowsAllProjects.push({
                                    mode: 'span',
                                    html: false,
                                    label: eleUserProject.data().name,
                                    color: eleUserProject.data().color,
                                    idsite: eleUserProject.data().id,
                                    id: eleUserProject.data().id,
                                    title: eleUserProject.data().name,
                                    count: eleUserProject.data().numberSites,
                                    permission: memberPerm,
                                    totalInv: eleUserProject.data().numberTeamMembers,
                                    members: eleUserProject.data().teamMembers,
                                    own: 'other',
                                    ownedBy: ownerData,
                                    counterErr: 0,
                                    loadErrors: false,
                                    counterSites: eleUserProject.data().numberSites,
                                    invitation: filterUserMember[0]
                                })
                            }
                        }

                        db.collection('Projects').doc(eleUserProject.data().id).collection('sites').get()
                        .then(queryArchiveProject => {
                            queryArchiveProject.forEach(site => {
                                if(site.data().status === 'archive') {
                                    this.existArchivedSites = true
                                }

                                // if(site.data().thumbnail) {
                                //     function imgExists(url, callback) {
                                //         var img = new Image()
                                //         img.onerror = function() {
                                //             callback(false)
                                //         }
                                //         img.onload = function () {
                                //             callback(true);
                                //         }
                                //         img.src = url
                                //     }

                                //     var checkImage = (exists) => {
                                //         // console.log(site.name, exists)
                                //         this.datasuggest.data[0].sites.push({ id: site.id, img: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b', name: site.data().name ? site.data().name : site.data().WebsiteURL, domainFixed: site.data().WebsiteURL, project: eleUserProject.data().name, status: site.data().status, domain: site.data().homepage, projectid: eleUserProject.data().id, color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)', own: 'me', invitation: '', imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b' })
                                //     }

                                //     imgExists(site.data().thumbnail, checkImage)
                                // } else {
                                    this.datasuggest.data[0].sites.push({ id: site.id, img: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b', name: site.data().name ? site.data().name : site.data().WebsiteURL, domainFixed: site.data().WebsiteURL, project: eleUserProject.data().name, status: site.data().status, domain: site.data().homepage, projectid: eleUserProject.data().id, color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)', own: 'me', invitation: '', imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b' })
                                // }
                            })

                            setTimeout(() => {
                                this.loaderSearch = false
                                this.fetchResults(this.searchSite)
                            }, 1500)
                        })
                    })

                    // loader all list
                    setTimeout(() => {
                        this.loaderProjects = false

                        // horizontal scroll
                        this.$nextTick(() => {
                            $(".top-scroll-horizontal-projects").scroll(() => {
                                // console.log(1)
                                $(".vgt-table").scrollLeft($(".top-scroll-horizontal-projects").scrollLeft());
                            });

                            // $("> .vgt-table").scroll(() => {
                            //   console.log(2)
                            //   $(".top-scroll-horizontal-projects").scrollLeft($(".vgt-table").scrollLeft());
                            // });
                        });

                        setTimeout(() => {
                            const miElemento = document.querySelector('.vgt-table');
                            if(miElemento) {
                                miElemento.addEventListener('scroll', () => {
                                    // console.log(2);
                                    document.querySelector('.top-scroll-horizontal-projects').scrollLeft = document.querySelector('.vgt-table').scrollLeft;
                                });
                            }
                        }, 1200)
                    }, 1000)

                    // horizontal scroll
                    this.$nextTick(() => {
                        $(".top-scroll-horizontal-projects").scroll(() => {
                            // console.log(1)
                            $(".vgt-table").scrollLeft($(".top-scroll-horizontal-projects").scrollLeft());
                        });

                        // $("> .vgt-table").scroll(() => {
                        //   console.log(2)
                        //   $(".top-scroll-horizontal-projects").scrollLeft($(".vgt-table").scrollLeft());
                        // });
                    });

                    setTimeout(() => {
                        const miElemento = document.querySelector('.vgt-table');
                        if(miElemento) {
                            miElemento.addEventListener('scroll', () => {
                                // console.log(2);
                                document.querySelector('.top-scroll-horizontal-projects').scrollLeft = document.querySelector('.vgt-table').scrollLeft;
                            });
                        }
                    }, 1200)
                })

                // db.collection('Users').doc(user.uid).get()
                // .then(userDoc => {
                if(!userLogDoc.data().nombre && !userLogDoc.data().contact && !userLogDoc.data().address1) {
                    if(this.firstLogin) {
                        this.logIdCompleteProfile()
                        localStorage.removeItem('firstLogin')
                    }
                }
            })
        }

        // set new limitCrawl sites to 600
        // db.collection('Sites').get()
        // .then(elesSite => {
        //     elesSite.forEach(eleSite => {
        //         if(eleSite.data().limitCrawl) {
        //             db.collection('Sites').doc(eleSite.id).update({
        //                 limitCrawl: 600
        //             })
        //             .then(() => console.log('updated'))
        //         } else {
        //             console.log(eleSite.id, eleSite.data().limitCrawl)
        //         }
        //     })
        // })

        // reset strings site
        // db.collection('Sites').get()
        // .then(elesSite => {
        //     elesSite.forEach(eleSite => {
        //         if(eleSite.data().stringSearch.includes('dev.')) {
        //             var newStrings = []
        //             eleSite.data().stringSearch.forEach(ele => {
        //                 if(ele !== 'dev.') {
        //                     newStrings.push(ele)
        //                 }
        //             })

        //             db.collection('Sites').doc(eleSite.id).update({
        //                 stringSearch: newStrings
        //             })
        //             .then(() => console.log('updated'))
        //         } else {
        //             console.log(eleSite.id, eleSite.data().stringSearch)
        //         }
        //     })
        // })

        // set strings site
        // db.collection('Sites').get()
        // .then(elesSite => {
        //     elesSite.forEach(eleSite => {
        //         if(!eleSite.data().stringSearch.includes('dev.')) {
        //             var newStrings = []
        //             eleSite.data().stringSearch.forEach(ele => {
        //                 // if(ele === 'Lorem Ipsum') {
        //                     // newStrings.push('Lorem','Ipsum')
        //                 // } else {
        //                     newStrings.push(ele)
        //                 // }
        //             })
        //             newStrings.push('dev.')

        //             db.collection('Sites').doc(eleSite.id).update({
        //                 stringSearch: newStrings
        //             })
        //             .then(() => console.log('updated'))
        //         } else {
        //             console.log(eleSite.id, eleSite.data().stringSearch)
        //         }
        //     })
        // })

        // reset sizes
        // db.collection('Sites').get()
        // .then(elesSite => {
        //     elesSite.forEach(eleSite => {
        //         if(eleSite.data().pageSizes) {
        //             db.collection('Sites').doc(eleSite.id).update({
        //                 pageSizes: []
        //             })
        //             .then(() => console.log('first'))
        //         }
        //     })
        // })
    },
    mounted() {
        this.events.forEach((event) => {
            window.addEventListener(event, this.getStatusCompletedProfile)
            window.addEventListener(event, this.getWithScroll)
        })
    },
    destroyed() {
        this.events.forEach((event) => {
            window.removeEventListener(event, this.getStatusCompletedProfile)
            window.removeEventListener(event, this.getWithScroll)
        })
    },
}
</script>

<style>
body {
  background: rgb(24, 30, 56) !important;
}
.autosuggest-sites .autosuggest__results-container {
    max-width: 350px !important;
}
.thTableProjects {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    font-weight: 300;
    padding-right: 0.75em !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* width: 365px !important; */
    /* max-width: 365px !important; */
    min-width: 155px !important;
}
.tdTableProjects {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    cursor: pointer;
    vertical-align: middle !important;
    /* width: 365px !important; */
    /* max-width: 365px !important; */
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-width: 155px !important;
    /* margin: 20px !important; */
}
.thTableProjectsSites {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    /* max-width: 425px; */
    font-weight: 300;
    padding-right: 0.75em !important;
    width: 120px !important;
    max-width: 120px !important;
    min-width: 90px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* display: block; */
}
.tdTableProjectsSites {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    vertical-align: middle !important;
    width: 120px !important;
    max-width: 120px !important;
    min-width: 90px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* display: block; */
}
.thTableProjectsOwned {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    /* max-width: 425px; */
    font-weight: 300;
    padding-right: 0.75em !important;
    width: 220px !important;
    max-width: 220px !important;
    min-width: 72px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* margin: 20px !important; */
    /* display: block; */
}
.tdTableProjectsOwned {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    vertical-align: middle !important;
    width: 220px !important;
    max-width: 220px !important;
    min-width: 72px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* display: block; */
}
.thTableProjectsMembers {
    background: transparent !important;
    border: 1px solid transparent !important;
    color: #A7AAB9 !important;
    font-size: 15px !important;
    /* max-width: 425px; */
    font-weight: 300;
    padding-right: 0.75em !important;
    width: 200px !important;
    max-width: 200px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /* margin: 20px !important; */
}
.tdTableProjectsMembers {
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #DCDCF2 !important;
    vertical-align: middle !important;
    width: 200px !important;
    max-width: 200px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.autosuggest-sites > div > input {
  width: 325px;
  height: 44px;
  border: 1px solid #A7AAB9;
  border-radius: 22px;
}
.autosuggest-sites.autosuggest__results-container {
  background: #fff !important;
}
@media (min-width: 601px) {
    .btn-all-proj-newp {
        padding-top: 14px !important;
    }
    .btn-all-proj-news {
        padding-top: 14px !important;
    }
    .btn-all-proj-arch {
        padding-top: 14px !important;
    }
}
@media (max-width: 600px) {
    .col1-all-projects {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col2-all-projects {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 600px) {
    /* inside btn */
    .btn-all-proj-newp {
        height: 36px !important;
        padding-top: 11px !important;
    }
    .btn-all-proj-newp svg {
        position: relative;
        left: -5px;
        top: -1px;
    }
    .btn-all-proj-newp span {
        position: relative;
        left: -5px;
        top: -1px;
    }
    .btn-all-proj-news {
        height: 36px !important;
        padding-top: 11px !important;
    }
    .btn-all-proj-news svg {
        position: relative;
        left: -5px;
        top: -1px;
    }
    .btn-all-proj-news span {
        position: relative;
        left: -5px;
        top: -1px;
    }

    /* block btn */
    .btn-all-proj-newp {
        float: left !important;
        position: absolute;
        left: 0px !important;
        margin-left: 1rem !important;
        padding: 8px;
    }
    .btn-all-proj-newp {
        width: 126px !important;
        height: 36px !important;
    }
    .btn-all-proj-news {
        float: left !important;
        position: absolute;
        left: 0px !important;
        margin-top: 0px;
        margin-left: 145px !important;
        padding: 8px;
    }
    .btn-all-proj-news {
        width: 100px !important;
        height: 36px !important;
    }
    .btn-all-proj-arch {
        float: left !important;
        position: absolute;
        left: 0px !important;
        margin-top: 45px;
        margin-left: 1rem !important;
        padding: 8px;
    }
    .btn-all-proj-arch {
        width: 126px !important;
        height: 36px !important;
        padding-top: 11px !important;
    }
    .title-all-projects {
        margin-top: 130px;
        display: block;
    }
    .top-scroll-horizontal-projects {
        margin-top: 0px;
    }
    .table-projects {
        margin-top: 0px;
    }
}
</style>